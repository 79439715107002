import styled from "styled-components";
import { colors } from "../../../config/styles";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  width: 100%;

  margin-top: 1rem;
  padding: 2rem;
  padding-bottom: 4rem;

  /* background: #2F363D; */

   background: #262f37; /* Old browsers */
   background: -moz-linear-gradient(
    left,
    #262f37 0%,
    #171c21 100%
  ); /* FF3.6-15 */
   background: -webkit-linear-gradient(
    left,
    #262f37 0%,
    #171c21 100%
  ); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(
    to right,
    #262f37 0%,
    #171c21 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#262f37', endColorstr='#171c21',GradientType=1 ); IE6-9

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);

  svg {
    text-align: center;

    path {
      display: block;
      margin: auto;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;

  overflow: hidden;
  flex-wrap: wrap;

  width: 100%;
  max-width: 72.5rem;

  min-height: 6rem;

  gap: 1rem;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  width: 20rem;

  gap: 0.25rem;

  font-size: 1.25rem;
  font-weight: bold;

  @media (min-width: 768px) {
    width: 14rem;

    font-size: 0.75rem;
  }
`;

export const Title = styled.span`
  color: #9eadbc;
  text-transform: uppercase;

  margin-bottom: 0.25rem;
`;

export const Item = styled.span`
  width: max-content;

  color: #5780a8;
`;

export const LinkItem = styled.a`
  width: max-content;

  color: #5780a8;

  cursor: pointer;
  text-decoration: none;

  :hover {
    filter: brightness(125%);
  }
`;

export const IconsItem = styled.span`
  display: flex;
  align-items: center;

  gap: 0.25rem;

  width: max-content;

  text-align: center;

  color: #5780a8;

  svg {
    fill: #5780a8;
    color: #5780a8;
  }
`;

export const SocialMedias = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  gap: 0.5rem;

  width: 100%;

  margin-bottom: 0.5rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #9eadbc;
      width: 1.2rem;

      transition: filter 0.2s;
    }

    :hover {
      svg {
        filter: brightness(125%);
      }
    }
  }
`;

export const CopyrightBlock = styled.div`
  font-size: 11px;
  font-weight: 300;
  color: #5780a8;
  text-align: center;
`;
