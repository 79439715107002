import React, { Component } from "react";
import styled from "styled-components";
import UserOnboarding from "./UserOnboarding";
import { UserContext } from "../../contexts/UserContext";
import { adSpots } from "../../config/ad-spots";
import TagManager from "react-gtm-module";
import { Footer } from "./Footer";
import WarningLGPD from "./WarningLGPD";
import UserService from "../../services/user";
import AdManagerUnit from "./AdManagerUnit";
import { initAdManager } from "../../config/gpt-ids";
import { withRouter } from "next/router";
import { BannerCTAHub } from "../Hubs/BannerCTAHub";
import HubsService from "../../services/hubs";
import { Header } from "./Header";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderCollapsed: false,
      AcceptedTerms: true,
      ctas: [],
    };
    this.appContainer = React.createRef();
  }

  componentDidMount() {
    const cookies = UserService.getCookies();
    if (!cookies.AcceptedTerms && this.state.AcceptedTerms) {
      this.setState({ AcceptedTerms: false });
    }

    if (adSpots.BACKGROUND.active) {
      this.scrollListener = window.addEventListener(
        "scroll",
        this.calculateHeaderCollapse
      );
    }

    initAdManager();

    TagManager.initialize({
      gtmId: "GTM-PQ73CC8",
      dataLayer: {
        path: window.location.pathname,
        title: document.title,
      },
    });

    TagManager.initialize({
      gtmId: "GTM-NHZ4HHC",
    });

    TagManager.initialize({
      gtmId: "GTM-WMFWNX3",
    });

    this.loadData();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.calculateHeaderCollapse);
  }

  calculateHeaderCollapse = () => {
    const { isHeaderCollapsed } = this.state;

    if (window.scrollY >= 300 && !isHeaderCollapsed) {
      this.setState({ isHeaderCollapsed: true });
    }

    if (window.scrollY < 300 && isHeaderCollapsed) {
      this.setState({ isHeaderCollapsed: false });
    }
  };

  calculateOffsetToEdge = () => {
    if (this.appContainer && this.appContainer.current) {
      const width = this.appContainer.current.offsetLeft;

      return `${width}px`;
    }

    return "calc(50% - 585px)";
  };

  getBackgroundTopPosition = () => {
    const { isHeaderCollapsed } = this.state;

    return isHeaderCollapsed ? 50 : 93;
  };

  trackBgLinkClick = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `${adSpots.BACKGROUND.analyticsKey}-CLICK`,
      });
    }
  };

  AcceptLGPDTerms = () => {
    this.setState({
      AcceptedTerms: true,
    });
    UserService.AcceptLGPDTerms();
  };

  async loadData() {
    let ctas = [];
    try {
      const { data } = await HubsService.getCtasHubs();
      ctas = data.data;
    } catch (error) {
      console.error("Error on loadData Ctas Hubs:", error.message);
    }
    this.setState({ ...this.state, ctas });
  }

  renderRub(hub) {
    return <BannerCTAHub key={hub.slug} hub={hub} />;
  }

  render() {
    const { userData } = this.context;

    const { noPadding, noBgad, backgroundColor, router } = this.props;

    const { AcceptedTerms } = this.state;

    return (
      <LayoutContainer
        style={{
          backgroundPosition: `center top ${this.getBackgroundTopPosition()}px`,
        }}
      >
        <Header />
        {!!this.state.ctas && !!this.state.ctas.length && (
          <CTAContainer>
            {this.state.ctas.map((hubCta) => this.renderRub(hubCta))}
          </CTAContainer>
        )}

        {!AcceptedTerms && (
          <WarningLGPD AcceptLGPDTerms={this.AcceptLGPDTerms} />
        )}

        {!userData.hasOnboarded && <UserOnboarding />}
        <SideAd left isHeaderCollapsed={this.state.isHeaderCollapsed}>
          {router.pathname === "/major-blasttv-23" ||
          router.pathname === "/iemrio" ||
          router.pathname.startsWith("/major/") ||
          router.pathname.startsWith("/iemrio/") ? (
            <AdManagerUnit name="MJH_BACKGROUND_TAKEOVER_LEFT_280x700" />
          ) : (
            <AdManagerUnit name="BACKGROUND_TAKEOVER_LEFT" />
          )}
        </SideAd>
        <SideAd isHeaderCollapsed={this.state.isHeaderCollapsed}>
          {router.pathname === "/major-blasttv-23" ||
          router.pathname === "/iemrio" ||
          router.pathname.startsWith("/major/") ||
          router.pathname.startsWith("/iemrio/") ? (
            <AdManagerUnit name="MJH_BACKGROUND_TAKEOVER_RIGHT_280x700" />
          ) : (
            <AdManagerUnit name="BACKGROUND_TAKEOVER_RIGHT" />
          )}
        </SideAd>
        <AppContainer
          id="AppContainer"
          noBgad={noBgad}
          noPadding={noPadding}
          ref={this.appContainer}
          backgroundColor={backgroundColor || "#f0f0f0"}
        >
          {this.props.children}
          <BottomAdmUnit name="BOTTOM_FIXED_SUPER_BANNER" closeable />
        </AppContainer>
        {!(
          router.pathname === "/major-blasttv-23" ||
          router.pathname === "/iemrio" ||
          router.pathname.startsWith("/major/") ||
          router.pathname.startsWith("/iemrio/")
        ) && <Footer />}
      </LayoutContainer>
    );
  }
}

Layout.contextType = UserContext;

export default withRouter(Layout);

const LayoutContainer = styled.div`
  margin-top: calc(63px + 4.625rem);
  width: fill-content;
  background-color: #f0f0f0;
  transition: all 0.02s;
  min-width: 768px;
  transition-timing-function: ease-in-out;

  @media screen and (max-width: 768px) {
    margin-top: calc(61px + 7.5rem);
  }
`;

const SideAd = styled.div`
  display: block;
  position: fixed;
  top: calc(74px + 4.625rem + 2.4rem);
  height: 100vh;
  width: calc(50% - 600px);
  max-width: 340px;
  background: transparent;
  z-index: 9;
  text-align: center;
  transition: all 0.5s;

  ${(props) => (props.left ? "left: 20px" : "right:20px")};

  @media screen and (max-width: 768px) {
    top: calc(64px + 7.5rem + 2.4rem);
  }

  @media screen and (max-width: 1550px) {
    display: none;
  }
`;

const AppContainer = styled.div`
  background: ${(props) => props.backgroundColor};
  margin: auto;
  padding-top: ${(props) => (props.noPadding ? "0px" : "24px")};
  min-height: calc(100vh - 24rem - 2rem - 1.9rem);
  /* position: relative; */
  ${(props) =>
    !props.noBgad && adSpots.BACKGROUND.active ? "max-width: 1170px" : ""}
`;

const BottomAdmUnit = styled(AdManagerUnit)`
  position: fixed;
  bottom: 0px;
  margin-bottom: -5px;
  width: 100%;
  text-align: center;
  left: 0;
  pointer-events: none;
  z-index: 12;

  & * {
    pointer-events: all;
  }

  img {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 137px;
  z-index: 9;

  @media screen and (max-width: 768px) {
    top: 182px;
  }
`;
